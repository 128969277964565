export const IS_PRODUCTION = process.env.NODE_ENV === "production";

export const SHOP_COUNTRY_LABELS = {
	"fr-FR": "France",
	"fr-BE": "Belgique",
	"nl-BE": "België",
	"en-GB": "United Kingdom",
	"de-DE": "Deutschland",
	"de-AT": "Österreich",
	"it-IT": "Italia",
	"es-ES": "España",
	"de-CH": "Schweiz",
	"fr-CH": "Suisse",
	"en-KE": "Kenya",
	"en-WW": "International",
};

export const WEBSITE_TUNNEL_TYPES = {
	HOLIDAYS: "sdpHolidays",
	STOPOVER: "sdpStopover",
	HOTEL_ONLY: "hotelOnly",
};

export const WEBSITE_TUNNEL_LABELS = {
	[WEBSITE_TUNNEL_TYPES.HOLIDAYS]: "root.tab.label.sdp",
	[WEBSITE_TUNNEL_TYPES.STOPOVER]: "root.tab.label.stopover",
	[WEBSITE_TUNNEL_TYPES.HOTEL_ONLY]: "root.tab.label.hotel.only",
};

export const BRANDS = {
	XX: "XX",
	AF: "AF",
	AR: "AR",
	VP: "VP",
	EK: "EK",
	HP: "HP",
	PV: "PV",
	VE: "VE",
	DD: "DD",
	MS: "MS",
	VC: "VC",
	TO: "TO",
	CD: "CD",
	LS: "LS",
	SE: "SE",
	UG: "UG",
	GP: "GP",
	TZ: "TZ",
	AX: "AX",
	SP: "SP",
	GE: "GE",
	OV: "OV",
	PS: "PS",
	LE: "LE",
	SV: "SV",
	LM: "LM",
	KQ: "KQ",
};

export const STOPOVER_DIRECTIONS = {
	OUTBOUND: 1,
	INBOUND: 2,
};

export const CABINS_CODE = {
	Economy: 1,
	Premium: 2,
	Business: 3,
	First: 4,
};

export const BRANDS_WITH_CUSTOM_HEADER = [BRANDS.CD, BRANDS.SP, BRANDS.HP];

export const BRANDS_WITH_LISTING = [BRANDS.SE, BRANDS.TZ, BRANDS.UG];

// brands that is not white label but still have access to my booking (airline brands etc....)
export const BRANDS_WITH_ACCESS_TO_MY_BOOKING = [BRANDS.PS, BRANDS.SV, BRANDS.AX, BRANDS.KQ];

// @see https://github.com/prettymuchbryce/http-status-codes
export const HTTP_STATUS_CODES = {
	OK: 200,
	UNAUTHORIZED: 401,
	BAD_REQUEST: 400,
	FORBIDDEN: 403,
	NOT_FOUND: 404,
	CONFLICT: 409,
	TOO_MANY_REQUEST: 429,
	INTERNAL_SERVER_ERROR: 500,
	GATEWAY_TIMEOUT: 504,
};
export const PAYMENT_ERROR_STATUS = {
	EXTERNAL_PAYMENT_STATUS_ABORTED: "EXTERNAL_PAYMENT_STATUS_ABORTED",
	CREDIT_CARD_TECHNICAL_ERROR: "CREDIT_CARD_TECHNICAL_ERROR",
	CREDIT_CARD_3DS_REFUSED: "CREDIT_CARD_3DS_REFUSED",
	CREDIT_CARD_REFUSED: "CREDIT_CARD_REFUSED",
	CREDIT_CARD_INFO: "CREDIT_CARD_INFO",
	PAYMENT_AGREEMENT_REFUSED: "PAYMENT_AGREEMENT_REFUSED",
	BOOK_EXTERNAL_PAYMENT_TECHNICAL_ERROR: "BOOK_EXTERNAL_PAYMENT_TECHNICAL_ERROR",
	BOOK_EXTERNAL_PAYMENT_STATUS_UNKNOWN: "BOOK_EXTERNAL_PAYMENT_STATUS_UNKNOWN",
	BOOK_EXTERNAL_PAYMENT_STATUS_REFUSED: "BOOK_EXTERNAL_PAYMENT_STATUS_REFUSED",
	EXTERNAL_PAYMENT_STATUS_ERROR: "EXTERNAL_PAYMENT_STATUS_ERROR",
};

export const CIVILITY = {
	MALE: "M",
	FEMALE: "F",
};

export const RESOLUTION = {
	XLARGE: "xlarge",
	LARGE: "large",
	MEDIUM: "medium",
	SMALL: "small",
	UNKNOWN: "unknown",
};

// Valeurs possible de la persona key resolution daans flagship
export const FS_RESOLUTIONS = {
	xlarge: "desktop",
	large: "desktop",
	medium: "mobile",
	small: "mobile",
	unknown: "unknown",
};

// Valeurs possible de la persona key resolution daans flagship
export const DATADOG_RESOLUTIONS = {
	xlarge: "desktop",
	large: "desktop",
	medium: "tablet",
	small: "mobile",
	unknown: "unknown",
};

export const CABINS = {
	ECONOMY: "Economy",
	PREMIUM: "Premium Economy",
	BUSINESS: "Business",
	FIRST: "First",
};

export const OFFER_TYPES = {
	ACCOMODATION_ONLY: "AO",
	FLIGHT_WITH_ACCOMMODATION: "FA",
};

export const OFFER_CONTRACT_TYPES = {
	RENTAL: "Rental",
	HOTEL: "Hotel",
};

export const OFFER_PRICE_TYPES = {
	FROM_PRICE_TYPE_SAVE_UP_TO: "FROM_PRICE_TYPE_SAVE_UP_TO",
	FROM_PRICE_TYPE_FROM: "FROM_PRICE_TYPE_FROM",
	FROM_PRICE_TYPE_FROM_WITH_FLIGHT: "FROM_PRICE_TYPE_FROM_WITH_FLIGHT",
	FROM_PRICE_TYPE_EXCLUSIVE: "FROM_PRICE_TYPE_EXCLUSIVE",
};

export const PROMO_CODE_STATUS = {
	REMOVED_PROMOCODE: "REMOVED_PROMOCODE",
	INVALID_PROMOCODE: "INVALID_PROMOCODE",
	SUCCESS: "SUCCESS",
};

export const FLIGHT_OPTIONS_STATUS = {
	ERROR: "ERROR",
	LOADING: "LOADING",
	NO_BAGAGE: "NO_BAGAGE",
	SUCCESS: "SUCCESS",
	SESSION_EXPIRED: "SESSION_EXPIRED",
};

// 500 internal erreur { status: TECHNICAL_FAILURE }
// 400 bad request sans body
export const QUOTATION_CODE_STATUS = {
	LOADING: "LOADING", // valeur par défaut coté front
	FULL_PROPERTY: "FULL_PROPERTY", // plus de dispo hotel 200
	FULL_FLIGHT: "FULL_FLIGHT", // plus de vol 200
	FULL_TRANSFER: "FULL_TRANSFER", // plus de vol 200
	MISSING_INCLUDED_ACTIVITY: "MISSING_INCLUDED_ACTIVITY",
	NOT_FOUND: "NOT_FOUND", // pas de résultat stopover par exemple
	SUCCESS: "SUCCESS",
	WARNING: "WARNING",
	TECHNICAL_FAILURE: "TECHNICAL_FAILURE", // erreur interne erreur 500
	SESSION_EXPIRED: "SESSION_EXPIRED", // devis trop vieux, meme message que actuellement // 200
	BAD_REQUEST: "BAD_REQUEST", // manque des données dans le payload // 400
};

export const QUOTATION_STATUS_VALIDE = [
	QUOTATION_CODE_STATUS.SUCCESS,
	QUOTATION_CODE_STATUS.WARNING,
];

export const QUOTATION_PRODUCT_EXPERIENCE = {
	HOTEL: "Hotel",
	RESIDENCE: "Résidence",
	CIRCUIT: "Circuit",
	AUTOTOUR: "Autotour",
	COMBINE: "Combiné",
	HOTEL_ACTIVITY: "Hotel + Activity",
	DOUBLON: "DOUBLON",
	HOTEL_MKT_PACKAGE: "Hotel MKT Package",
	CAMPING: "Camping",
	CRUISE: "Cruise",
	HOTEL_CAR_RENTAL: "Hotel + Car Rental",
	HOTEL_CLUB_TO: "Hotel CLUB TO",
	BLACKLIST: "BLACKLIST",
	INACTIVE: "INACTIVE",
};

export const NEWSLETTER_FREQUENCIES = {
	NONE: "none",
	WEEKLY: "weekly",
	ALL: "all",
};

export const NEWSLETTER_REMARKETING = {
	ACCEPT: "accept",
	DECLINE: "decline",
};

export const NEWSLETTER_SMS = {
	ACCEPT: "accept",
	DECLINE: "decline",
};

export const FLIGHTS_TAG = {
	CHEAPEST: "CHEAPEST",
	RECOMMENDED: "RECOMMENDED",
	CHEAPEST_TRAIN: "CHEAPEST_TRAIN",
	DIRECT_FLIGHT: "DIRECT_FLIGHT",
	BAGGAGE_INCLUDED: "BAGGAGE_INCLUDED",
	CHEAPEST_PRIMARY_AIRPORT: "CHEAPEST_PRIMARY_AIRPORT",
};

export const TRANSPORT_COMPANY_CODES = {
	EUROSTAR: "9F",
	VUELING: "VY",
	RYANAIR: "FR",
	TRANSAVIA_NL: "HV",
	TRANSAVIA: "TO",
	AIR_FRANCE: "AF",
	AIR_TAHITI_NUI: "TN",
	FRENCH_BEE: "BN",
	AIR_CARAIBES: "TX",
};

export const MEDIA_TYPE = {
	VIDEO: "video",
};

export const VIDEO_AUTOPLAY = {
	FALSE: 0,
	TRUE: 1,
};

export const SLIDE_SHOW_PHOTO_TYPES = {
	PHOTOS: "PHOTOS",
	MULTIMEDIA: "MULTIMEDIA",
};

export const MY_BOOKINGS_STATUS = {
	SUCCESS: "SUCCESS",
	TECHNICAL_FAILURE: "TECHNICAL_FAILURE",
	SESSION_EXPIRED: "SESSION_EXPIRED",
	LOADING: "LOADING", // non renvoyé par le serveur, c'est la valeur par défaut du status
};

export const SEARCH_MY_BOOKINGS_STATUS = {
	SUCCESS: "SUCCESS",
	TECHNICAL_FAILURE: "TECHNICAL_FAILURE",
	SESSION_EXPIRED: "SESSION_EXPIRED",
	NOT_FOUND: "NOT_FOUND",
	TOO_MANY_REQUEST: "TOO_MANY_REQUEST",
	LOADING: "LOADING", // non renvoyé par le serveur, c'est la valeur par défaut du status
};

export const PRODUCT_PAGE_CODE_STATUS = {
	LOADING: "LOADING",
	SUCCESS: "SUCCESS",
	TECHNICAL_FAILURE: "TECHNICAL_FAILURE",
	SESSION_EXPIRED: "SESSION_EXPIRED",
};

export const PROMO_CODE_CODE_STATUS = {
	SUCCESS: "SUCCESS",
	INVALID_PROMOCODE: "INVALID_PROMOCODE", // 200
};

export const PRE_BOOK_CODE_STATUS = {
	SUCCESS: "SUCCESS",
	WARNING: "WARNING",
	FULL_PROPERTY: "FULL_PROPERTY",
	FULL_FLIGHT: "FULL_FLIGHT",
	FULL_TRANSFER: "FULL_TRANSFER",
	TECHNICAL_FAILURE: "TECHNICAL_FAILURE",
	SESSION_EXPIRED: "SESSION_EXPIRED",
};

export const BOOK_STATUS = {
	SUCCESS: "SUCCESS",
	TECHNICAL_FAILURE: "TECHNICAL_FAILURE",
	SESSION_EXPIRED: "SESSION_EXPIRED",
	LOADING: "LOADING",
	WARNING_BOOKING_OK_FULLPAYMENT_FAILED: "WARNING_BOOKING_OK_FULLPAYMENT_FAILED",
	WARNING_BOOKING_FAILED_FULLPAYMENT_FAILED: "WARNING_BOOKING_FAILED_FULLPAYMENT_FAILED",
	WARNING_BOOKING_FAILED_FULLPAYMENT_OK: "WARNING_BOOKING_FAILED_FULLPAYMENT_OK",
	EXTERNAL_REDIRECT_REQUEST: "EXTERNAL_REDIRECT_REQUEST",
	CREDIT_CARD_INFO: "CREDIT_CARD_INFO",
	CREDIT_CARD_TECHNICAL_ERROR: "CREDIT_CARD_TECHNICAL_ERROR",
	CREDIT_CARD_REFUSED: "CREDIT_CARD_REFUSED",
	CREDIT_CARD_3DS_REFUSED: "CREDIT_CARD_3DS_REFUSED",
	WARNING_FULL_PROPERTY: "FULL_PROPERTY",
	EXTERNAL_PAYMENT_STATUS_ERROR: "EXTERNAL_PAYMENT_STATUS_ERROR",
	CHILDREN_AGES_DISCREPANCY: "CHILDREN_AGES_DISCREPANCY",
	INFANTS_AGES_DISCREPANCY: "INFANTS_AGES_DISCREPANCY",
	PAYMENT_AGREEMENT_REFUSED: "PAYMENT_AGREEMENT_REFUSED",
	EXTERNAL_ACTION_REQUEST: "EXTERNAL_ACTION_REQUEST",
	EXTERNAL_HTML_REQUEST: "EXTERNAL_HTML_REQUEST",
	RETRY: "RETRY",
	DUPLICATE_BOOKING: "DUPLICATE_BOOKING",
	WARNING: "WARNING",
};

export const PROFILE_CODE_STATUS = {
	SUCCESS: "SUCCESS",
	FAILURE: "FAILURE",
	LOADING: "LOADING",
};

export const PRODUCTS_VISIBILITY = {
	PREVIEW: "preview",
	CURRENT: "current",
};

// must match formik fields name
export const SDP_PRODUCTS_FILTERS_KEY = {
	STARS: "stars",
	BOARDS: "boards",
	DESTINATIONS: "destinations",
	GUESTS_RATING: "guestsrating",
	BUDGET: "budget",
	FLASH_SALE: "flashsale",
};

export const PRODUCTS_FILTERS_KEY = {
	ZONE: "zone",
	BADGE: "badge",
	DESTINATION: "destination",
	TOP_DESTINATION: "topDestination",
	TOPIC: "topic",
	MONTH: "month",
	PERIOD: "period",
	MERCHANDISINGS: "merchandisings",
	CATEGORY: "category",
	START_AT: "startAt",
	END_AT: "endAt",
	DISCOUNT_PERCENTAGE: "discountPercentage",
};

export const LANDING_PAGE_TYPE = {
	COUNTRIES: "countries",
};

export const COOKIES_AUTH_EXPIRES = 365 * 20;

export const COOKIES_OAUTH_EXPIRES = 1;

export const FIRST_NAME_LIMIT_CHARS = 2;
export const LAST_NAME_LIMIT_CHARS = 1;
export const CITY_MAX_CHARS = 51;

export const FLIGHTS_PAGE_SIZE = 3; // nombre de vols max affiché au load dans la page de quotation
export const ACCOMMODATIONS_PAGE_SIZE = 3;
export const QUOTATION_SESSION_EXPIRED_TIMEOUT = 10 * 60 * 1000; // 10 minutes
export const QUOTATION_MAX_ACTIVITIES = 5;
export const QUOTATION_MAX_DEFAULT_ACTIVITIES = 3;

export const SESSION_BIID_EXPIRATION_MAX_AGE = 20;

export const SDP_PRODUCTS_PAGE_SIZE = 20;

export const AUTH_LAYER_SHOW_DELAY = 1000; // ne pas mettre 0 car bug : La frame apparait meme si tu as un uuid dans le localestorage

export const STRATEGY = {
	NAVIGATION_FIRST: "navigationFirst",
	SIGNUP_FIRST: "signupFirst",
	TRANSACTION_FIRST: "transactionFirst",
	OAUTH: "oAuth",
	PRODUCT_FIRST: "productFirst",
	AUTH_FLASHSALE: "authFlashsale",
	NONE: "none",
};

export const LISTING_DISPLAY_MODE = {
	LIST: "list",
	CARD: "card",
};

// Attention les valeurs ici doivent correspondre aux valeurs Adyen
// Voir https://github.com/Adyen/adyen-cse-web/blob/v0.1.18/js/addOns/adyen.cardtype.js#L107
export const CREDIT_CARDS_TYPES = {
	CB: "cb",
	VISA: "visa",
	VISA_ELECTRON: "electron",
	MASTERCARD: "mc",
	AMEX: "amex",
	MAESTRO: "maestro",
};

export const CB_NUMBER_MAX_LENGTH = 19;

export const DATALAYER_PAYMENT_TYPES = {
	CREDIT_CARD: "CreditCard",
	CHEQUE_VACANCES: "ChequeVacances",
	TRANSFER: "Transfer",
};

export const EXTERNAL_PAYMENT_STATUS = {
	EXTERNAL_PAYMENT_STATUS_ABORTED: "EXTERNAL_PAYMENT_STATUS_ABORTED",
	EXTERNAL_PAYMENT_STATUS_ERROR: "EXTERNAL_PAYMENT_STATUS_ERROR",
	EXTERNAL_PAYMENT_STATUS_PENDING: "EXTERNAL_PAYMENT_STATUS_PENDING",
	EXTERNAL_PAYMENT_STATUS_SUCCESS: "EXTERNAL_PAYMENT_STATUS_SUCCESS",
};

export const EXTERNAL_PAYMENT_HTTP_METHODS = {
	GET: "GET",
};

export const BOOK_EXTERNAL_PAYMENT_STATUS = {
	BOOK_EXTERNAL_PAYMENT_STATUS_UNKNOWN: "BOOK_EXTERNAL_PAYMENT_STATUS_UNKNOWN",
	BOOK_EXTERNAL_PAYMENT_STATUS_ABORTED: "BOOK_EXTERNAL_PAYMENT_STATUS_ABORTED",
	BOOK_EXTERNAL_PAYMENT_STATUS_REFUSED: "BOOK_EXTERNAL_PAYMENT_STATUS_REFUSED",
	BOOK_EXTERNAL_PAYMENT_TECHNICAL_ERROR: "BOOK_EXTERNAL_PAYMENT_TECHNICAL_ERROR",
};

// "France", "Guadeloupe", "Guyane", "Réunion", "Mayotte", "Martinique"
export const FRANCE_DOM_COUNTRIES = ["FR", "GP", "GF", "RE", "YT", "MQ"];

export const IATA_DOM_COUNTRIES = [
	"BBR",
	"DSD",
	"SFG",
	"DSD",
	"LSS",
	"PTP",
	"SBH",
	"SFC",
	"FDF",
	"CAY",
	"MPY",
	"OXP",
	"LDX",
	"REI",
	"XAU",
	"RUN",
	"ZSE",
	"FSP",
	"MQC",
	"DZA",
	"FUT",
	"WLS",
	"AAA",
	"APK",
	"AGR",
	"BOB",
	"FHZ",
	"FAV",
	"FGU",
	"HOI",
	"HHZ",
	"HIX",
	"HUH",
	"KKR",
	"MKP",
	"XMH",
	"MVT",
	"MAU",
	"MOZ",
	"NAU",
	"NHV",
	"NUK",
	"PPT",
	"PKP",
	"PUK",
	"RFP",
	"RGI",
	"REA",
	"RUR",
	"TKP",
	"TKX",
	"TKV",
	"TTI",
	"TIH",
	"TUB",
	"UAH",
	"UAP",
	"VHZ",
	"GMR",
	"TGJ",
	"BMY",
	"KNQ",
	"ILP",
	"KOC",
	"LIF",
	"GEA",
	"PUV",
	"MEE",
	"TOU",
	"UVE",
	"NOU",
];

export const PASSENGER_TYPE = {
	ADULT: "adult",
	INFANT: "infant",
	CHILD: "child",
};

export const PRODUCT_CATEGORY_TYPES = {
	STAR: "Star",
	TRIDENT: "Trident",
};

export const SPONSORSHIP_SOURCES = {
	FRIEND_BOOKED: "friendBooked",
	FRIEND_SUBSCRIBED: "friendSubscribed",
	SPONSORSHIP_ACCEPTED: "sponsorshipAccepted",
};

export const SPONSORSHIP_INVITATION_ERRORS = {
	CANT_SPONSOR_YOURSELF: "CANT_SPONSOR_YOURSELF",
	INVALID_EMAIL_FORMAT: "INVALID_EMAIL_FORMAT",
	ALREADY_FRIEND: "ALREADY_FRIEND",
	EMAIL_PROVIDER_NOT_ACCEPTED: "EMAIL_PROVIDER_NOT_ACCEPTED",
};

export const LISTING_ITEM_TYPES = {
	PRODUCT: "product",
	MERCH_CAROUSSEL: "carousselMerch",
	MERCH_SIMPLE: "simpleMerch",
};

export const PAYMENT_METHODS = {
	CARD_CSE: "adyen_cse_card",
	CARD: "adyen_api_card",
	IDEAL: "ideal",
	SOFORT: "sofort",
	GIROPAY: "giropay",
	PAYPAL: "paypal",
	CHECKOUT: "checkout",
	CHEQUE_VACANCES: "cheque",
	CASINO: "casino",
	ALMA: "alma",
	PLEDG: "pledg",
	BCMC: "bcmc",
	SWISS_BILLING: "swissbilling",
	POSTFINANCE_CARD: "postfinance_card",
	POSTFINANCE_EFINANCE: "postfinance_efinance",
};

export const EXTERNAL_PAYMENT_METHODS = [
	PAYMENT_METHODS.IDEAL,
	PAYMENT_METHODS.SOFORT,
	PAYMENT_METHODS.GIROPAY,
	PAYMENT_METHODS.POSTFINANCE_EFINANCE,
	PAYMENT_METHODS.POSTFINANCE_CARD,
	PAYMENT_METHODS.PAYPAL,
	PAYMENT_METHODS.PLEDG,
	PAYMENT_METHODS.ALMA,
];

export const PAYMENT_TERMS = {
	"1x": 1,
	"2x": 2,
	"4x": 4,
	"10x": 10,
};

export const MY_BOOKING_STATUS = {
	CONFIRMED: "CONFIRMED",
	NOT_CONFIRMED: "NOTCONFIRMED",
	CANCELED: "CANCELED",
};
export const LISTING_ROUTE_PATH = "listing";
export const FLASH_SALE_ROUTE_PATH = "les-ventes-flash";

export const SWISSBILLING_INVOICE_TYPES = {
	EMAIL: "email",
	PAPER: "paper",
};

export const TRANSPORTATION_TYPES = {
	TRAIN: "Train",
	FLIGHT: "Flight",
};
export const CASINO_URL_MENTIONS_LEGALES = "https://floa.fr/mentions-legales";

export const ALERT_TYPE = {
	ERROR: "error",
	ERROR_ICON: "error-icon",
	SUCCESS: "success",
};

export const QUOTATION_ITEMS_TYPE = {
	TRIP: "TRIP",
	SPECIAL_DISCOUNT: "SPECIAL_DISCOUNT",
	BOOKING_FEES: "BOOKING_FEES",
	ADJUSTMENT_TRIP: "ADJUSTMENT_TRIP",
};

export const QUOTATION_PAYLOAD_PATHS = [
	"offer",
	"departureCity",
	"duration",
	"departureDate",
	"endDate",
	"price",
	"publicPrice",
	"adults",
	"infants",
	"children",
	"config",
	"rentalAccommodation",
	"passengers",
];

export const LOADING_BAR_STATUS = {
	HIDDEN: "HIDDEN",
	LOADING: "LOADING",
	FINISHED: "FINISHED",
};

export const ATOL_PROTECTED_SIZE = {
	BIG: "BIG",
	SMALL: "SMALL",
};

export const UK_ATOL_NUMBER = "11475";

export const BADGE_DEFAULT_COLORS = {
	BORDER: "#000",
	TEXT: "#000",
	BACKGROUND: "#FFF",
};

export const BADGE_CODES = {
	COUP_DE_COEUR: "CDC",
};

export const NAVLOGS_SHOW_SLIDES_NUMBER = {
	[RESOLUTION.LARGE]: 3,
	[RESOLUTION.XLARGE]: 3,
	[RESOLUTION.MEDIUM]: 2.3,
	[RESOLUTION.SMALL]: 1.3,
};

export const NAVLOGS_SHOW_SLIDES_NUMBER_PRODUCTS_CAROUSEL = {
	[RESOLUTION.LARGE]: 3,
	[RESOLUTION.XLARGE]: 3,
	[RESOLUTION.MEDIUM]: 2.3,
	[RESOLUTION.SMALL]: 1.15,
};

export const LOGIN_URL = "/auth/local/login";
export const SIGNUP_URL = "/auth/local/signup";
export const REGISTER_URL = "/auth/local/register";

export const PRICES_COUNT_THRESHOLD = 7;

// insurance code pattern to detect for the "insurance" "dont want one"
export const NO_INSURANCE_PATTERN = "NO_INSURANCE";

export const PAYMENT_COUPON_TYPE = {
	PROMOCODE: "promocode",
	CREDIT: "credit",
};

export const USABLE_CREDITS_STATUS = {
	SUCCESS: "SUCCESS",
	LOADING_CREDIT_NOTES: "LOADING_CREDIT_NOTES",
	LOADING_COUPONS: "LOADING_COUPONS",
};

export const KEY_CODES = {
	ENTER: 13,
};

export const CREDIT_TYPES = {
	TRAVELBACK: "travelback",
	GENERIC: "generic",
	SPONSORSHIP: "sponsorship",
};

// https://github.com/catamphetamine/libphonenumber-js#gettype
export const LIB_PHONE_NUMBER_TYPES = {
	FIXED_LINE: "FIXED_LINE",
	FIXED_LINE_OR_MOBILE: "FIXED_LINE_OR_MOBILE",
	UNDEFINED: "UNDEFINED",
	MOBILE: "MOBILE",
	PERSONAL_NUMBER: "PERSONAL_NUMBER",
	UAN: "UAN",
	PAGER: "PAGER",
	VOICEMAIL: "VOICEMAIL",
	VOIP: "VOIP",
	SHARED_COST: "SHARED_COST",
	TOLL_FREE: "TOLL_FREE",
	PREMIUM_RATE: "PREMIUM_RATE",
};

export const FREE_CANCELLATION_EN_COUNTRIES = [
	"United Arab Emirates",
	"Maldives",
	"Tanzania",
	"Thailand",
	"Seychelles",
	"Mauritius",
];

export const SPECIAL_OFFER_TYPES = {
	INSURANCE: "INSURANCE",
	REFUNDABLE: "REFUNDABLE",
};

export const NEGOCIATED_TYPES = {
	OTHER: "OTHER",
	ACCOMMODATION: "ACCOMMODATION",
	TRAIN: "TRAIN",
	FLIGHT: "FLIGHT",
	TRANSPORTATION: "TRANSPORTATION",
	TRANSFER: "TRANSFER",
	BOARD: "BOARD",
	EXCURSION: "EXCURSION",
	CAR_RENTAL: "CAR-RENTAL",
	SPECIAL_OFFER: "SPECIAL_OFFER",
};

export const INSURANCE_STANDARD = "STANDARD";

export const SDP_HERO_TEXT_ALIGN = {
	LEFT: "left",
	RIGHT: "right",
	CENTER: "center",
};

export const SDP_SEARCH_MARKETING_BLOCKS = {
	PRODUCT_LIST: "productList",
	REASONS_TO_BOOK: "reasonsToBook",
	REINSURANCE: "reinsurance",
	IMMERSIVE_BANNER: "immersiveBanner",
	BANNER: "banner",
	MERCH_CAROUSSEL: "merchCarousel",
	PP_CAROUSEL: "PPCarousel",
	ONE_MERCH_BANNER: "oneMerchBanner",
	PAYMENT_DESCRIPTION_BLOCK: "paymentDescriptionBlock",
	MEMBERSHIP: "membership",
	CONTENT_IMAGE_CARROUSEL: "ContentImageCarrousel",
};

export const STATIC_PAGE_BLOCKS = {
	CENTERED_LOGO_BLOCK: "centeredLogoBlock",
	PHOTO_ARTICLES: "photoArticles",
};

export const UTM_SOURCE = { REMARKETING: "remarketing" };
export const UTM_MEDIUM = { EMAIL_REMARKETING: "email_remarketing" };

export const MAX_PASSENGERS_ALLOWED = 9;

// hide expired credits note if expiration date > 1 year
export const SHOW_CREDIT_NOTES_EXPIRATION_MAX_YEAR = 1;

export const MAX_PRODUCTS_TO_RENDER_SERVER_SIDE = 4;

export const SORT_TYPES = {
	RECOMMENDED: "recommended",
	PRICE_ASC: "priceAscendant",
	PRICE_DESC: "priceDescendant",
	TRIPADVISOR: "tripadvisor",
	HOLIDAYCHECK: "holidaycheck",
};

export const DEFAULT_SORT_TYPE = [
	SORT_TYPES.RECOMMENDED,
	SORT_TYPES.PRICE_ASC,
	SORT_TYPES.PRICE_DESC,
];

export const SORT_TYPES_TRANSLATION_KEYS = {
	[SORT_TYPES.RECOMMENDED]: "sort.types.recommended",
	[SORT_TYPES.PRICE_ASC]: "sort.types.price.asc",
	[SORT_TYPES.PRICE_DESC]: "sort.types.price.desc",
	[SORT_TYPES.TRIPADVISOR]: "sort.types.tripadvisor",
	[SORT_TYPES.HOLIDAYCHECK]: "sort.types.holidaycheck",
};

export const ONE_MERCH_BANNER_CATEGORY = "OneMerchBanner";

// au niveau legal, on ne peut pas afficher les frais ni le montant de la première échéance d'un paiement un plus de 4 fois
// car ca devient un crédit
export const MAX_PAYMENT_INSTALMENTS_WITH_FEES_DISPLAYED = 4;

export const FLAGSHIP_EVENT_KPIS = {
	CLICK_ON_LISTING_FILTER_ALL: "CLICK_ON_LISTING_FILTER_ALL",
	CLICK_ON_LISTING_FILTER_DESTINATION: "CLICK_ON_LISTING_FILTER_DESTINATION",
	CLICK_ON_LISTING_FILTER_TOPIC: "CLICK_ON_LISTING_FILTER_TOPIC",
	CLICK_ON_LISTING_FILTER_CALENDAR: "CLICK_ON_LISTING_FILTER_CALENDAR",
	CLICK_ON_SDP_LISTING_SORT_RATING: "CLICK_ON_SDP_LISTING_SORT_RATING",
	CLICK_ON_SDP_LISTING_SORT_RECO: "CLICK_ON_SDP_LISTING_SORT_RECO",
	CLICK_ON_SDP_LISTING_SORT_PRICE_ASC: "CLICK_ON_SDP_LISTING_SORT_PRICE_ASC",
	CLICK_ON_SDP_LISTING_SORT_PRICE_DESC: "CLICK_ON_SDP_LISTING_SORT_PRICE_DESC",
	CLICK_ON_PP_PRODUCT_CARD: "CLICK_ON_PP_PRODUCT_CARD",
	CLICK_ON_SEARCH_BANNER: "CLICK_ON_SEARCH_BANNER",
	CLICK_ON_SEARCH_POPULAR_OFFERS: "CLICK_ON_SEARCH_POPULAR_OFFERS",
};

export const FLAGSHIP_SCREENVIEW_KPIS = {
	SCREENVIEW_PP_PRODUCT: "SCREENVIEW_PP_PRODUCT",
	SCREENVIEW_PP_QUOTE: "SCREENVIEW_PP_QUOTE",
	SCREENVIEW_PP_PAYMENT: "SCREENVIEW_PP_PAYMENT",
	SCREENVIEW_PP_LISTING: "SCREENVIEW_PP_LISTING",
	SCREENVIEW_SDP_LISTING: "SCREENVIEW_SDP_LISTING",
	SCREENVIEW_SDP_QUOTE: "SCREENVIEW_SDP_QUOTE",
	SCREENVIEW_SDP_PAYMENT: "SCREENVIEW_SDP_PAYMENT",
};

export const FLAGSHIP_TRANSACTION_KPIS = {
	PURCHASE: "PURCHASE",
};

export const ITINERARY_INFO_TYPE = {
	SCHEDULE: "SCHEDULE",
	FLIGHT_INFO: "FLIGHT_INFO",
	FLIGHT_PREVIEW_INFO: "FLIGHT_PREVIEW_INFO",
	TRANSIT_INFO: "TRANSIT_INFO",
	STOPOVER_SCHEDULE: "STOPOVER_SCHEDULE",
	STOPOVER_INFO: "STOPOVER_INFO",
	STOPOVER_PREVIEW_INFO: "STOPOVER_PREVIEW_INFO",
};

export const FLIGHT_TYPE = {
	DEPARTURE: "DEPARTURE",
	ARRIVAL: "ARRIVAL",
};
export const DATE_INPUT_TYPE = {
	DEPARTURE: "DEPARTURE",
	END: "END",
};

export const RATING_TYPE = {
	TRIP_ADVISOR: "TRIP_ADVISOR",
	HOLIDAY_CHECK: "HOLIDAY_CHECK",
};

const allowedSymbols = "!\"#$%&'()*+,-./:;<=>?@[\\\\\\]^_`{|}~";
export const PASSWORD_LIMIT_CHARS = 9;
export const PASSWORD_POLICY_REGEX = {
	LIMIT_CHARS: /^.{10,}$/,
	UPPERCASE: /[A-Z]+/,
	DIGIT: /[0-9]+/,
	SPECIAL_CHAR: new RegExp(`[${allowedSymbols}]`),
};

// eslint-disable-next-line no-useless-escape
const nameAvoidSymbols = '!"#$%&()*+,./:;<=>?@[\\\\\\]^_`{|}~';
export const NAME_POLICY_REGEX = {
	DIGIT: /[0-9]+/,
	SPECIAL_CHAR: new RegExp(`[${nameAvoidSymbols}]`),
};

export const MAP_SORT_TYPE_EVENT_NAME = {
	[SORT_TYPES.RECOMMENDED]: FLAGSHIP_EVENT_KPIS.CLICK_ON_SDP_LISTING_SORT_RECO,
	[SORT_TYPES.PRICE_ASC]: FLAGSHIP_EVENT_KPIS.CLICK_ON_SDP_LISTING_SORT_PRICE_ASC,
	[SORT_TYPES.PRICE_DESC]: FLAGSHIP_EVENT_KPIS.CLICK_ON_SDP_LISTING_SORT_PRICE_DESC,
	[SORT_TYPES.HOLIDAYCHECK]: FLAGSHIP_EVENT_KPIS.CLICK_ON_SDP_LISTING_SORT_RATING,
	[SORT_TYPES.TRIPADVISOR]: FLAGSHIP_EVENT_KPIS.CLICK_ON_SDP_LISTING_SORT_RATING,
};

export const FS_QUOTE_EVENT_NAME = {
	CLICK_ON_QUOTE_BACK: "CLICK_ON_QUOTE_BACK",
	CLICK_ON_QUOTE_MORE_ROOMS: "CLICK_ON_QUOTE_MORE_ROOMS",
	CLICK_ON_QUOTE_ROOM_UPGRADES: "CLICK_ON_QUOTE_ROOM_UPGRADES",
	CLICK_ON_SEARCH_CTA: "CLICK_ON_SEARCH_CTA",
};

export const FS_SDP_FORM_SEARCH_EVENT_NAME = {
	CLICK_ON_MINI_SEARCH: "CLICK_ON_MINI_SEARCH",
};

export const FS_PAYMENT_MODE_EVENT_NAME = {
	1: "CLICK_ON_PAYMENT_OPTION_TOTAL",
	2: "CLICK_ON_PAYMENT_OPTION_2X",
	4: "CLICK_ON_PAYMENT_OPTION_4X",
	10: "CLICK_ON_PAYMENT_OPTION_10X",
};

export const FS_SEARCH_ENGINE_EVENT_NAME = {
	CLICK_ON_PP_SEARCH_CTA: "CLICK_ON_PP_SEARCH_CTA",
	CLICK_ON_PP_SEARCH_CALENDAR_STRESS_HIGH: "CLICK_ON_PP_SEARCH_CALENDAR_STRESS_HIGH",
};

export const USERS_TYPE = {
	ALL: "All",
	LOGGED_IN: "Logged-in",
	ANONYMOUS: "Anonymous",
};
export const TRANSFER_TYPE = {
	OUTBOUND: "OUTBOUND",
	INBOUND: "INBOUND",
};

export const CUSTOMER_REQUEST_TYPE = {
	"my-requests": "MY_REQUESTS",
	"new-request": "NEW_REQUEST",
	CANCELLATION_REQUEST: "CANCELLATION",
};

export const SDP_ROTATION_DATE_FORMAT = "yyyy-MM-dd";

export const MERCH_CTA_POSITION_VALUES = {
	HIDDEN: "hidden",
	NORTH_EAST: "north_east",
	NORTH: "north",
	NORTH_WEST: "north_west",
	WEST: "west",
	SOUTH_WEST: "south_west",
	SOUTH: "south",
	SOUTH_EAST: "south_east",
	EAST: "east",
	CENTER: "center",
};

export const FLIGHT_WARNING_TYPE = {
	OUTBOUND: 2,
	INBOUND: 1,
};

export const PAGE_TYPE = {
	GRADIENT: "gradient",
};

export const COUPON_TYPE = {
	PROMOTION: "PROMOTION",
	SPONSORSHIP: "SPONSORSHIP",
	TRAVELBACK: "TRAVELBACK",
};

export const COUPON_STATUS = {
	UPCOMING: "UPCOMING",
	EXPIRED: "EXPIRED",
	USED: "USED",
	AVAILABLE: "AVAILABLE",
	EXPIRE_SOON: "EXPIRE_SOON",
	USING: "USING",
};

export const COUPON_UPCOMING_FOR = {
	friendBooked: "friendBooked",
	friendSubscribed: "friendSubscribed",
	sponsorshipAccepted: "sponsorshipAccepted",
};

export const LOYALTY_CREDIT_TYPES = {
	TRAVEL_BACK: "TRAVEL_BACK",
	INSCRIPTION: "INSCRIPTION",
};

export const LOYALTY_DISCOUNT_TYPES = {
	CURRENCY: "CURRENCY",
	PERCENT: "PERCENT",
};

export const NAVIGATION_STATE_VALUES = {
	REDIRECTED_FROM_BOOKING_AUTH: "redirectedFromBookingAuth",
};

export const CREDITS_INSCRIPTION_WAIT_DELAY = 5000;

export const INSPIRATION_MERCHANDISING = "MOTEUR_INSPIRATION";

export const DATADOG_ERROR_BLACKLIST = [
	/https:\/\/tags.tiqcdn.com/,
	/swiper.esm.bundle.js/,
	/https:\/\/www.googletagmanager.com/,
	/https:\/\/gp.cdn.woopic.com/,
	/https:\/\/c.woopic.com/,
	/https:\/\/cdn.cookielaw.org/,
	/https:\/\/widgets.outbrain.com/,
	/node_modules/,
];

export const PRODUCT_EXPERIENCES_WITH_HOTEL = [
	"Hotel",
	"Combiné",
	"Hotel + Activity",
	"Hotel + Car Rental",
	"Hotel Maldives",
	"Hotel CLUB TO",
];

export const STRESS_MARKETING_LEVEL = {
	HIGH: "HIGH",
	MEDIUM: "MEDIUM",
	LOW: "LOW",
};

export const PRICE_CHANGE_STATUS = {
	UP: "UP",
	DOWN: "DOWN",
};

export const DESTINATION_LEVELS = {
	DISTRICT: "District",
	ZONE: "Zone",
	COUNTRY: "Country",
	REGION: "Region",
	RESORT: "Resort",
	OTHER: "listing.filter.destination.others.label",
};

export const DESTINATION_USA_CODE = [
	601,
	455,
	4746,
	971,
	2545,
	1142,
	1480,
	2648,
	1168,
	1589,
	1705,
	1873,
	1238,
	1635,
	2588,
	747,
	1015,
	1313,
	598,
	2406,
	992,
	2582,
	4715,
	4716,
	595,
	2405,
	4714,
	744,
	508,
	209,
	3702,
	3705,
	4447,
	1024,
	1572,
	4705,
];

export const BG_GRADIENT_ANIMATION = {
	NONE: "NONE",
	AUTO: "AUTO",
	MOUSE_MOVE: "MOUSE_MOVE",
};

export const TOP_TOPIC_FILTER_VALUES = ["Tout inclus", "Mer et soleil"]; // TODO actuellement en dure en attendant de le recuperer depuis une source
export const PRODUCT_EXPERIENCES = {
	HOTEL: "Hotel",
	CIRCUIT: "Circuit",
	COMBINE: "Combiné",
	AUTOTOUR: "Autotour",
	CRUISE: "Cruise",
};

export const PRICING_SUPPLEMENT = {
	FLIGHT_SUPPLEMENT: "FLIGHT_SUPPLEMENT",
	BAGGAGE_SUPPLEMENT: "BAGGAGE_SUPPLEMENT",
	CABIN_CLASS_SUPPLEMENT: "CABIN_CLASS_SUPPLEMENT",
	ACCOMMODATIONS_SUPPLEMENT: "ACCOMMODATIONS_SUPPLEMENT",
	BOARD_SUPPLEMENT: "BOARD_SUPPLEMENT",
};

export const TUNNEL_STEP_STATUS = {
	ACTIVE: "ACTIVE",
	DISABLED: "DISABLED",
	PASSED: "PASSED",
};
