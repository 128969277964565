import { memo, useContext } from "react";
import PropTypes from "prop-types";
import AppGlobalsContext from "app/AppGlobalsContext";
import { useIntl } from "react-intl";
import Helmet from "react-helmet";
import { BRANDS } from "app/constants";

const getDynamicStyles = (themeFromSanity, brand, theme = {}) => {
	let mergedTheme = {
		colors: {
			colorPrimary: {
				hex: theme.colors?.colorPrimary?.hex || themeFromSanity.colors?.colorPrimary?.hex,
			},
			colorPrimaryDark: {
				hex:
					theme.colors?.colorPrimaryDark?.hex ||
					themeFromSanity.colors?.colorPrimaryDark?.hex,
			},
			colorPrimaryLight: {
				hex:
					theme.colors?.colorPrimaryLight?.hex ||
					themeFromSanity.colors?.colorPrimaryLight?.hex,
			},
			colorSecondary: {
				hex:
					theme.colors?.colorSecondary?.hex ||
					themeFromSanity.colors?.colorSecondary?.hex,
			},
			colorSecondaryDark: {
				hex:
					theme.colors?.colorSecondaryDark?.hex ||
					themeFromSanity.colors?.colorSecondaryDark?.hex,
			},
			black: {
				hex: theme.colors?.black?.hex || themeFromSanity.colors?.black?.hex,
			},
			greyDark: {
				hex: theme.colors?.greyDark?.hex || themeFromSanity.colors?.greyDark?.hex,
			},
			greyLight: {
				hex: theme.colors?.greyLight?.hex || themeFromSanity.colors?.greyLight?.hex,
			},
			greyMedium: {
				hex: theme.colors?.greyMedium?.hex || themeFromSanity.colors?.greyMedium?.hex,
			},
			error: {
				hex: theme.colors?.error?.hex || themeFromSanity.colors?.error?.hex,
			},
			valid: {
				hex: theme.colors?.valid?.hex || themeFromSanity.colors?.valid?.hex,
			},
		},
		font: {
			enableCustomFont: themeFromSanity.font?.enableCustomFont,
			fontFamily: themeFromSanity.font?.fontFamily,
		},
	};

	// pour les marques Plug And Play, on utilise la police Open Sans par défaut lorsque la police personnalisée n'est pas activée
	// dans Sanity
	const defaultFontFamily = brand === BRANDS.GE ? "opensans" : "";

	return `
            body {
              		${
						mergedTheme?.colors?.colorPrimary?.hex
							? "--color-primary: " + mergedTheme?.colors?.colorPrimary?.hex
							: ""
					};
				 	${
						mergedTheme?.colors?.colorPrimaryDark?.hex
							? "--color-primary-dark: " + mergedTheme?.colors?.colorPrimaryDark?.hex
							: ""
					};
					${
						mergedTheme?.colors?.colorPrimaryLight?.hex
							? "--color-primary-light: " +
							  mergedTheme?.colors?.colorPrimaryLight?.hex
							: ""
					};
					
					${
						mergedTheme?.colors?.colorSecondary?.hex
							? "--color-secondary: " + mergedTheme?.colors?.colorSecondary?.hex
							: ""
					};
					
					${
						mergedTheme?.colors?.colorSecondaryDark?.hex
							? "--color-secondary-dark: " +
							  mergedTheme?.colors?.colorSecondaryDark?.hex
							: ""
					};
					
					${
						mergedTheme?.colors?.colorSecondaryLight?.hex
							? "--color-secondary-light: " +
							  mergedTheme?.colors?.colorSecondaryLight?.hex
							: ""
					};
					
					${mergedTheme?.colors?.black?.hex ? "--black: " + mergedTheme?.colors?.black?.hex : ""};
					${mergedTheme?.colors?.greyDark?.hex ? "--grey-dark: " + mergedTheme?.colors?.greyDark?.hex : ""};
					${
						mergedTheme?.colors?.greyLight?.hex
							? "--grey-light: " + mergedTheme?.colors?.greyLight?.hex
							: ""
					};
					
					${
						mergedTheme?.colors?.greyMedium?.hex
							? "--grey-medium: " + mergedTheme?.colors?.greyMedium?.hex
							: ""
					};
					
					${mergedTheme?.colors?.error?.hex ? "--error: " + mergedTheme?.colors?.error?.hex : ""};
					${mergedTheme?.colors?.valid?.hex ? "--valid: " + mergedTheme?.colors?.valid?.hex : ""};
					
				--font-family: ${
					mergedTheme?.font?.enableCustomFont
						? mergedTheme?.font?.fontFamily
						: defaultFontFamily
				};
            }
        `;
};

const getFontFromTheme = theme => theme?.font?.fontFamily?.replace(" ", "+");

const DefaultHeadMetadata = ({
	googleSiteVerificationId,
	brandDisplayName,
	theme = {},
	sanityTheme = {},
	metaTags = [],
}) => {
	const { brand, shop, envVars, partnerCode, isRTL } = useContext(AppGlobalsContext);

	const lang = shop.slice(0, 2);

	const environment = envVars.ENVIRONMENT;

	let ogImage = `https://res.cloudinary.com/perfectstay/image/upload/f_auto,q_auto/brands/${brand}/og-${brand.toLowerCase()}.jpg`;

	if (partnerCode === "AFHOFRFR") {
		ogImage = `https://res.cloudinary.com/perfectstay/image/upload/f_auto,q_auto/brands/${brand}/og-afh.jpg`;
	}

	const intl = useIntl();

	const styles = getDynamicStyles(sanityTheme, brand, theme);

	const fontFamily = getFontFromTheme(sanityTheme);

	const shouldCustomizeFont = sanityTheme?.font?.enableCustomFont;

	return (
		<Helmet>
			<html lang={lang} dir={isRTL ? "rtl" : "ltr"} />
			<title>
				{intl.formatMessage({ id: "page.title.default" }, { siteTitle: brandDisplayName })}
			</title>
			<meta
				property="og:title"
				content={intl.formatMessage(
					{ id: "page.title.default" },
					{ siteTitle: brandDisplayName }
				)}
			/>

			<meta
				name="description"
				content={intl.formatMessage(
					{ id: "page.description.default" },
					{ siteTitle: brandDisplayName }
				)}
			/>

			<meta
				property="og:description"
				content={intl.formatMessage(
					{ id: "page.description.default" },
					{ siteTitle: brandDisplayName }
				)}
			/>

			<meta property="og:image" content={ogImage} />
			<meta property="og:image:alt" content={brandDisplayName} />
			<meta name="thumbnail" content={ogImage} />
			<meta property="og:type" content="website" />
			<meta name="apple-mobile-web-app-title" content={brandDisplayName} />
			<meta
				name="robots"
				content={environment?.includes("live") ? "noindex, follow" : "noindex, nofollow"}
			/>

			{googleSiteVerificationId && (
				<meta name="google-site-verification" content={googleSiteVerificationId} />
			)}

			{metaTags.map((meta, index) => {
				return <meta key={index} name={meta.name} content={meta.content} />;
			})}

			{envVars.FACEBOOK_APP_ID && (
				<link rel="preconnect" href="https://connect.facebook.net" />
			)}
			{envVars.GOOGLE_CLIENT_ID && (
				<link rel="preconnect" href="https://accounts.google.com/gsi/client" />
			)}
			<link rel="preconnect" href="https://res.cloudinary.com" />
			<link rel="preconnect" href="https://www.tripadvisor.com" />
			<link rel="preconnect" href="https://www.googletagmanager.com" />
			<link rel="preconnect" href="https://www.google-analytics.com" />
			<link rel="preconnect" href="https://x6pc9ierpi.execute-api.eu-west-1.amazonaws.com" />
			<link
				rel="icon"
				href={
					theme?.favicon
						? theme.favicon
						: `https://res.cloudinary.com/perfectstay/image/upload/f_auto,q_auto/brands/${brand}/favicon-${brand.toLowerCase()}.png`
				}
			/>

			{theme?.logo && (
				<link rel="preload" href={theme?.logo} as="image" type="image/svg+xml" />
			)}

			{!theme?.logo && (
				<link
					rel="preload"
					href={`https://res.cloudinary.com/perfectstay/image/upload/icon-logo/icon-logo-${brand}-${shop}-white.svg`}
					as="image"
					type="image/svg+xml"
				/>
			)}

			{!theme?.logo && (
				<link
					rel="preload"
					href={`https://res.cloudinary.com/perfectstay/image/upload/icon-logo/icon-logo-${brand}-${shop}.svg`}
					as="image"
					type="image/svg+xml"
				/>
			)}

			{shouldCustomizeFont && fontFamily && (
				<link rel="preconnect" href="https://fonts.googleapis.com" />
			)}

			{shouldCustomizeFont && fontFamily && (
				<link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
			)}

			{shouldCustomizeFont && fontFamily && (
				<link
					rel="stylesheet"
					href={`https://fonts.googleapis.com/css2?family=${fontFamily}:wght@400;600&display=swap`}
				/>
			)}

			<style type="text/css">{`${styles}`}</style>

			{envVars.FACEBOOK_APP_ID && <meta name="fb:app_id" content={envVars.FACEBOOK_APP_ID} />}
			{envVars.GOOGLE_CLIENT_ID && (
				<script src="https://accounts.google.com/gsi/client" async />
			)}
		</Helmet>
	);
};

DefaultHeadMetadata.propTypes = {
	brandDisplayName: PropTypes.string,
	googleSiteVerificationId: PropTypes.string,
	sanityTheme: PropTypes.object,
	theme: PropTypes.object,
	metaTags: PropTypes.array,
};

export default memo(DefaultHeadMetadata);
