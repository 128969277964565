import PropTypes from "prop-types";
import { memo, useState, useCallback } from "react";
import { FormattedMessage, FormattedPlural, FormattedNumber } from "react-intl";
import Modal from "react-modal";
import TripAdvisorDetailContainer from "app/pages/FicheProduit/components/TripAdvisor/TripAdvisorDetail/TripAdvisorDetailContainer";
import "./TripAdvisor.scss";
import Typography, { TYPOGRAPHY_VARIANTS } from "app/pages/.shared/Typography/Typography";

const TripAdvisor = ({ id, rating, reviewsCount, awardLabel, locationName }) => {
	const [isModalOpen, setIsOpen] = useState(false);
	const ratingValue = rating.toString().indexOf(".") === -1 ? rating + ".0" : rating;

	const openModal = useCallback(() => {
		setIsOpen(true);
	}, []);
	const closeModal = useCallback(() => {
		setIsOpen(false);
	}, []);

	return (
		<>
			<div className="tripadvisor" data-testid="tripadvisor" onClick={openModal}>
				<div className="tripadvisor__rating">
					<img
						src={`/static/icons/tripadvisor/${ratingValue}-MCID-5.svg`}
						alt={`Trip advisor rating ${ratingValue}`}
					/>
				</div>

				{reviewsCount > 0 && (
					<div className="tripadvisor__reviews">
						<Typography variant={TYPOGRAPHY_VARIANTS.SMALL}>
							<FormattedPlural
								value={reviewsCount}
								one={
									<FormattedMessage
										id="product.review.count.singular"
										values={{
											reviewsCount: 1,
										}}
									/>
								}
								other={
									<FormattedMessage
										id="product.review.count.plural"
										values={{
											reviewsCount: (
												<FormattedNumber
													value={reviewsCount}
													styles="decimal"
												/>
											),
										}}
									/>
								}
							/>
						</Typography>
						<i className="icon icon--tripadvisor-info tripadvisor__icon-detail" />
					</div>
				)}

				{awardLabel && awardLabel}
			</div>

			<Modal
				isOpen={isModalOpen}
				portalClassName="tripadvisor__portal"
				onRequestClose={closeModal}
			>
				<div className="tripadvisor-modal__body">
					<div className="tripadvisor-modal__head">
						<div
							className="tripadvisor-modal__title"
							data-testid="tripadvisor-detail-modal-title"
						>
							{locationName}
						</div>
						<i
							className="icon icon--cross-grey-dark tripadvisor-modal__close"
							data-testid="tripadvisor-detail-modal-close"
							onClick={closeModal}
						/>
					</div>
					<div className="tripadvisor-modal__content">
						<TripAdvisorDetailContainer tripAdvisorId={id} />
					</div>
				</div>
			</Modal>
		</>
	);
};

TripAdvisor.propTypes = {
	id: PropTypes.string,
	locationName: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	rating: PropTypes.number,
	reviewsCount: PropTypes.number,
	awardLabel: PropTypes.element,
};

export default memo(TripAdvisor);
